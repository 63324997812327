import * as React from 'react';

const IoT = () => {
  return (
    <div className=" mt-32 max-w-5xl mx-auto relative leading-relaxed px-4">
      <h1 className="font-display text-5xl leading-normal font-semibold">
        Internet of Things (IoT) Services
      </h1>
      <div className="w-full">
        <div className="border-default border-2 inline-block rounded-full px-8 bg-lightgreen my-4 py-1 font-semibold">
          Zegates Services
        </div>
      </div>

      <div className="grid sm:grid-cols-2 my-24 gap-4 ">
        <img
          src="/images/services/assembly-line-iot-zegates.jpg"
          alt="IoT Zegates"
          className=" rounded-2xl"
        />

        <div className="flex items-center justify-center">
          <p className="sm:max-w-96">
            At Zegates, we harness the power of the Internet of Things (IoT) to
            help businesses innovate, optimize operations, and achieve
            unprecedented levels of efficiency and connectivity. Our IoT
            solutions are designed to meet the diverse needs of our clients,
            enabling them to unlock new opportunities and drive digital
            transformation. Here’s how Zegates can elevate your business with
            our comprehensive IoT services
          </p>
        </div>
      </div>
      <h1 className="font-display text-5xl leading-normal font-semibold text-center my-16">
        IoT Consulting and Strategy
      </h1>

      <div className="grid sm:grid-cols-2 mt-8 gap-4">
        <div>
          <p className="mb-8 sm:max-w-96">
            We guide businesses through the IoT adoption process, ensuring they
            develop a robust strategy that aligns with their goals and maximizes
            ROI.
          </p>
          <div className="flex justify-center sm:justify-start">
            <img
              src="/images/services/iot-zegates.jpg"
              alt="IoT Zegates"
              className="rounded-2xl"
            />
          </div>
        </div>

        <div className="">
          <h3 className="text-lg my-4 font-bold">IoT Roadmap Development</h3>

          <p className="mb-4">
            Our experts help you create a detailed IoT roadmap, outlining the
            steps for successful implementation and integration.
          </p>

          <h3 className="text-lg my-2 font-bold">Feasibility Analysis</h3>

          <p className="mb-4">
            We assess the feasibility of IoT projects, considering factors such
            as cost, scalability, and potential benefits.
          </p>
          <h3 className="text-lg my-2 font-bold">Technology Selection</h3>

          <p className="mb-4">
            We assist in selecting the right IoT technologies and platforms that
            best fit your business needs and objectives.
          </p>
        </div>
      </div>

      <h1 className="font-display text-5xl leading-normal font-semibold text-center my-16">
        Custom IoT Solutions
      </h1>

      <div className="grid sm:grid-cols-2 mt-8 gap-12 mb-16">
        <div className="">
          <h3 className="text-lg my-4 font-bold">
            IoT System Design and Architecture
          </h3>

          <p className="mb-4">
            Our team designs robust IoT systems that ensure seamless
            connectivity, data flow, and integration with existing systems
          </p>

          <h3 className="text-lg my-2 font-bold">Descriptive Analytics</h3>

          <p className="mb-4">
            We provide comprehensive data analysis to understand past
            performance and identify patterns and anomalies.
          </p>

          <h3 className="text-lg my-2 font-bold">IoT Device Integration</h3>

          <p className="mb-4">
            We integrate various IoT devices, sensors, and actuators into your
            infrastructure, ensuring smooth communication and interoperability.
          </p>

          <h3 className="text-lg my-2 font-bold">Edge Computing</h3>

          <p className="mb-4">
            We implement edge computing solutions to process data closer to its
            source, reducing latency and improving response times.
          </p>
        </div>
        <div>
          <p className="mb-8 max-w-96">
            We specialize in developing tailor-made IoT solutions that address
            specific business challenges and requirements.
          </p>
          <img
            src="/images/services/iot-zegates-smart-home.jpg"
            alt="IoT Zegates"
            className="rounded-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default IoT;
